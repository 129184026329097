/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Built-in scalar representing a local date */
  LocalDate: string;
  /** Base64-encoded binary */
  Base64String: string;
  /** UUID String */
  UUID: string;
  /** Long type */
  Long: any;
  /** Supported formats: [yyyy-MM-dd'T'HH:mm:ss+ZZ:ZZ, yyyy-MM-dd'T'HH:mm:ss.qqq] */
  LocalDateTime: any;
  /** Unrepresentable type */
  UNREPRESENTABLE: any;
};

export type Query = {
  /** Returns pickup time range for given postal code. */
  pickupTime?: Maybe<PickupTime>;
  /** Returns list of addresses for authenticated user. Returns UNAUTHORIZED error when called without valid authorization. */
  addresses?: Maybe<Array<Maybe<Address>>>;
  /** Returns list of cases for authenticated user sorted by creation date descending. Returns UNAUTHORIZED error when called without valid authorization. */
  cases?: Maybe<CasesPayload>;
  /** Returns route detail (we need dDepot from it so far) for given zip code and country. It has to be called for each address (zip). Returns VALIDATION error_code if there is no route found. */
  route?: Maybe<RouteDetail>;
  /** Returns specified address if exists and belongs to authenticated user. Returns UNAUTHORIZED error when called without valid authorization. */
  address?: Maybe<Address>;
  /** Returns list of banks. */
  banks?: Maybe<Array<Maybe<Bank>>>;
  /** Returns profile data for authenticated user. Returns UNAUTHORIZED error when called without valid authorization. */
  profile?: Maybe<Profile>;
  /** Returns QR code(s) for given case. Returns QR_CODE_ERROR, when QR code generation fails. */
  qrCodes?: Maybe<Array<Maybe<Scalars['Base64String']>>>;
  /** Returns payment detail. */
  payment?: Maybe<PayURequest>;
  /** Returns true if authenticated user has given the consent, false otherwise. Returns UNAUTHORIZED error when called without valid authorization. */
  consent: Scalars['Boolean'];
  /** Retrieves configuration data. Returns UNAUTHORIZED error when called without valid authorization and/or user is not an admin. */
  config?: Maybe<ConfigData>;
  /** Returns case detail. */
  case?: Maybe<CaseData>;
};


export type QueryPickupTimeArgs = {
  code: Scalars['String'];
};


export type QueryAddressesArgs = {
  offset?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryCasesArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryRouteArgs = {
  data: DepotValidationInput;
};


export type QueryAddressArgs = {
  id: Scalars['UUID'];
};


export type QueryQrCodesArgs = {
  id: Scalars['UUID'];
};


export type QueryPaymentArgs = {
  id: Scalars['UUID'];
};


export type QueryCaseArgs = {
  id: Scalars['UUID'];
};

export type Mutation = {
  /** Stores the source of user's account. Returns UNAUTHORIZED error when called without valid authorization. */
  storeAccountSource: Scalars['Boolean'];
  /** Saves profile for current user. Returns UNAUTHORIZED error when called without valid authorization. */
  saveProfile?: Maybe<Profile>;
  /** Saves address for to authenticated user. Returns UNAUTHORIZED error when called without valid authorization. */
  saveAddress?: Maybe<Address>;
  /** Creates case in backend. */
  createCase?: Maybe<CaseData>;
  /** Creates order in PayU to allow customer to make payment. Returns WRONG_STATE if called on case not in CREATED or TO_BE_PAID, NOT_FOUND when case does not exist. */
  createPayment?: Maybe<PayUCreateOrderResponse>;
  /** Deletes address for authenticated user. Returns UNAUTHORIZED error when called without valid authorization. */
  deleteAddress: Scalars['Boolean'];
  /** Stores the reason why user deleted account. Returns UNAUTHORIZED error when called without valid authorization. */
  deleteAccount: Scalars['Boolean'];
  /** Saves consent for current user (email). Returns UNAUTHORIZED error when called without valid authorization. */
  saveConsent?: Maybe<Consent>;
  /** Sets user's email as verified. Returns NOT_FOUND if given userId does not exist. */
  verifyEmail?: Maybe<Scalars['String']>;
  /** Saves configuration data. Returns UNAUTHORIZED error when called without valid authorization and/or user is not an admin. */
  saveConfig?: Maybe<ConfigData>;
};


export type MutationStoreAccountSourceArgs = {
  source: Scalars['String'];
};


export type MutationSaveProfileArgs = {
  profile: SaveProfileInput;
};


export type MutationSaveAddressArgs = {
  address: SaveAddressInput;
};


export type MutationCreateCaseArgs = {
  data: CaseDataInput;
};


export type MutationCreatePaymentArgs = {
  redirectUrl: Scalars['String'];
  id: Scalars['UUID'];
};


export type MutationDeleteAddressArgs = {
  id: Scalars['UUID'];
};


export type MutationDeleteAccountArgs = {
  reason: Scalars['String'];
};


export type MutationSaveConsentArgs = {
  consent: SaveConsentInput;
};


export type MutationVerifyEmailArgs = {
  userId: Scalars['String'];
};


export type MutationSaveConfigArgs = {
  config: SaveConfigInput;
};

export type Account = {
  bankCode?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
};

export type CaseData = {
  cashOnDelivery?: Maybe<CashOnDelivery>;
  destAddress?: Maybe<PlaceAddress>;
  doNotTip: Scalars['Boolean'];
  fragile: Scalars['Boolean'];
  id?: Maybe<Scalars['UUID']>;
  items: Array<ParcelPartDescription>;
  language: Scalars['String'];
  price: Price;
  recipient: Recipient;
  sendHow: PickupType;
  sendWhen?: Maybe<Scalars['LocalDate']>;
  sender: Sender;
  srcPudoId?: Maybe<Scalars['String']>;
  status?: Maybe<CaseStatus>;
  tracking?: Maybe<Scalars['String']>;
  utm?: Maybe<Utm>;
};

export type Address = {
  city: Scalars['String'];
  country: Country;
  dic?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  forInvoicing: Scalars['Boolean'];
  ico?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
  name: Scalars['String'];
  name2?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  type: AddressType;
  zip: Scalars['String'];
};

export enum PlaceType {
  LOCKER = 'LOCKER',
  PARCEL_SHOP = 'PARCEL_SHOP'
}


export type Utm = {
  campaign?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
};


export type RecipientInput = {
  /** max number of characters: 35 */
  name?: Maybe<Scalars['String']>;
  type: AddressType;
  /** max number of characters: 35 */
  companyName?: Maybe<Scalars['String']>;
  /** max number of characters: 70 */
  note?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  /** max number of characters: 16 */
  phone: Scalars['String'];
  /** max number of characters: 7 */
  postalCode: Scalars['String'];
  phonePrefix: Scalars['String'];
  /** max number of characters: 35 */
  city: Scalars['String'];
  ico?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** max number of characters: 48 */
  street: Scalars['String'];
  country: Country;
};

export type UtmInput = {
  campaign?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  content?: Maybe<Scalars['String']>;
};

export type Consent = {
  dateTime?: Maybe<Scalars['LocalDateTime']>;
  email?: Maybe<Scalars['String']>;
  granted: Scalars['Boolean'];
};

export type ProfileAccountInput = {
  iban?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
};


export type AccountInput = {
  number?: Maybe<Scalars['String']>;
  bankCode?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
};

export type Status = {
  statusCode: Scalars['String'];
};

export type ParcelPartDescription = {
  parcelNumber?: Maybe<Scalars['Long']>;
  parcelValue?: Maybe<Scalars['Int']>;
  size: ParcelSize;
  weight?: Maybe<Scalars['Int']>;
};

export type Recipient = {
  city: Scalars['String'];
  companyName?: Maybe<Scalars['String']>;
  country: Country;
  dic?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ico?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  phonePrefix: Scalars['String'];
  postalCode: Scalars['String'];
  street: Scalars['String'];
  type: AddressType;
};

export type SaveConfigInput = {
  data: Scalars['String'];
};

export type Sender = {
  city: Scalars['String'];
  cityInvoice?: Maybe<Scalars['String']>;
  country: Country;
  countryInvoice?: Maybe<Country>;
  dic?: Maybe<Scalars['String']>;
  dicInvoice?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  ico?: Maybe<Scalars['String']>;
  icoInvoice?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameInvoice?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  phone: Scalars['String'];
  phonePrefix: Scalars['String'];
  postalCode: Scalars['String'];
  postalCodeInvoice?: Maybe<Scalars['String']>;
  street: Scalars['String'];
  streetInvoice?: Maybe<Scalars['String']>;
  type: AddressType;
  typeInvoice?: Maybe<AddressType>;
};

export type SaveConsentInput = {
  /** set to true, if user is giving the consent, false when revoking */
  granted: Scalars['Boolean'];
};

export type CaseDataInput = {
  fragile: Scalars['Boolean'];
  items: Array<ParcelPartDescriptionInput>;
  sender: SenderInput;
  utm?: Maybe<UtmInput>;
  recipient: RecipientInput;
  tracking?: Maybe<Scalars['String']>;
  /** used only for output */
  status?: Maybe<CaseStatus>;
  sendHow: PickupType;
  doNotTip: Scalars['Boolean'];
  cashOnDelivery?: Maybe<CashOnDeliveryInput>;
  sendWhen?: Maybe<Scalars['LocalDate']>;
  price: PriceInput;
  /** GUID for this case. Used only for output */
  id?: Maybe<Scalars['UUID']>;
  language: Scalars['String'];
  /** pudoId of pickup point or locker user selected for drop off */
  srcPudoId?: Maybe<Scalars['String']>;
  /** Details of pickup point or locker, all fields must be provided, or whole destAddress must be null */
  destAddress?: Maybe<PlaceAddressInput>;
};

export type PlaceAddressInput = {
  /** pudoId of pickup point or locker */
  id?: Maybe<Scalars['String']>;
  type?: Maybe<PlaceType>;
  /** max number of characters: 35 */
  city?: Maybe<Scalars['String']>;
  /** max number of characters: 48 */
  street?: Maybe<Scalars['String']>;
  /** max number of characters: 7 */
  postalCode?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  /** max number of characters: 35 */
  name?: Maybe<Scalars['String']>;
};

export type ParcelPartDescriptionInput = {
  weight?: Maybe<Scalars['Int']>;
  size: ParcelSize;
  /** Package number assigned to this case. Used only for output */
  parcelNumber?: Maybe<Scalars['Long']>;
  /** min allowed value is 50001 */
  parcelValue?: Maybe<Scalars['Int']>;
};

export type Price = {
  anotherCountry: Scalars['Int'];
  basePrice: Scalars['Int'];
  cashOnDelivery: Scalars['Int'];
  courierDelivery: Scalars['Int'];
  courierPickup: Scalars['Int'];
  extraInsurance: Scalars['Int'];
  total: Scalars['Int'];
};

export enum CaseStatus {
  ACCEPTED = 'ACCEPTED',
  AT_DELIVERY_DEPOT = 'AT_DELIVERY_DEPOT',
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  DATA_TRANSMITTED = 'DATA_TRANSMITTED',
  DELIVERED = 'DELIVERED',
  HANDOVER_CONSIGNOR_TO_PARCELSHOP = 'HANDOVER_CONSIGNOR_TO_PARCELSHOP',
  HANDOVER_TO_PARCELSHOP = 'HANDOVER_TO_PARCELSHOP',
  ON_THE_ROAD = 'ON_THE_ROAD',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  PAID = 'PAID',
  PICKUP_FROM_PARCELSHOP_BY_CONSIGNEE = 'PICKUP_FROM_PARCELSHOP_BY_CONSIGNEE',
  RETURN_TO_SENDER = 'RETURN_TO_SENDER',
  SENT_TO_DPD = 'SENT_TO_DPD',
  TO_BE_PAID = 'TO_BE_PAID'
}


export enum Country {
  AT = 'AT',
  BE = 'BE',
  CZ = 'CZ',
  DE = 'DE',
  EE = 'EE',
  FR = 'FR',
  HU = 'HU',
  IT = 'IT',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  NL = 'NL',
  PL = 'PL',
  SK = 'SK'
}

export enum PickupType {
  COURIER = 'COURIER',
  LOCKER = 'LOCKER',
  PARCEL_SHOP = 'PARCEL_SHOP'
}

export type SaveProfileInput = {
  phonePrefix?: Maybe<Scalars['String']>;
  account?: Maybe<ProfileAccountInput>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};


export type Bank = {
  code: Scalars['String'];
  name: Scalars['String'];
};

export type PriceInput = {
  /** Fee for the pickup, not necessarily only by courier */
  courierPickup: Scalars['Int'];
  extraInsurance: Scalars['Int'];
  cashOnDelivery: Scalars['Int'];
  anotherCountry: Scalars['Int'];
  /** Fee for the delivery, not necessarily only by courier */
  courierDelivery: Scalars['Int'];
  /** max allowed value is 10000 */
  total: Scalars['Int'];
  basePrice: Scalars['Int'];
};

export type PickupTime = {
  fromHour: Scalars['Int'];
  toHour: Scalars['Int'];
};

export type ProfileAccount = {
  bankCode?: Maybe<Scalars['String']>;
  iban?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  swift?: Maybe<Scalars['String']>;
};

export type CashOnDelivery = {
  account: Account;
  cashOnDeliveryValue: Scalars['Int'];
  paymentNote?: Maybe<Scalars['String']>;
  variableSymbol?: Maybe<Scalars['String']>;
};

export type Profile = {
  account?: Maybe<ProfileAccount>;
  email?: Maybe<Scalars['String']>;
  emailVerified: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  phonePrefix?: Maybe<Scalars['String']>;
};

export type CashOnDeliveryInput = {
  cashOnDeliveryValue: Scalars['Int'];
  paymentNote?: Maybe<Scalars['String']>;
  account: AccountInput;
  variableSymbol?: Maybe<Scalars['String']>;
};

export type RouteDetail = {
  depot: Scalars['String'];
  mpsAllowed?: Maybe<Scalars['String']>;
  sort?: Maybe<Scalars['String']>;
};

export type PayUCreateOrderResponse = {
  extOrderId: Scalars['String'];
  orderId: Scalars['String'];
  redirectUri: Scalars['String'];
  status: Status;
};

export type SaveAddressInput = {
  phonePrefix?: Maybe<Scalars['String']>;
  name2?: Maybe<Scalars['String']>;
  country: Country;
  phone?: Maybe<Scalars['String']>;
  zip: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  type: AddressType;
  dic?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  /** Include if updating address, when not sent new address will be created. */
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  street: Scalars['String'];
  /** True if this address is set for Invoicing, false otherwise. */
  forInvoicing: Scalars['Boolean'];
  ico?: Maybe<Scalars['String']>;
};

export type SenderInput = {
  country: Country;
  type: AddressType;
  /** max number of characters: 16 */
  phone: Scalars['String'];
  /** max number of characters: 35 */
  name: Scalars['String'];
  /** max number of characters: 48 */
  street: Scalars['String'];
  /** max number of characters: 7 */
  postalCodeInvoice?: Maybe<Scalars['String']>;
  /** max number of characters: 35 */
  cityInvoice?: Maybe<Scalars['String']>;
  dic?: Maybe<Scalars['String']>;
  typeInvoice?: Maybe<AddressType>;
  icoInvoice?: Maybe<Scalars['String']>;
  dicInvoice?: Maybe<Scalars['String']>;
  /** max number of characters: 35 */
  nameInvoice?: Maybe<Scalars['String']>;
  countryInvoice?: Maybe<Country>;
  /** max number of characters: 35 */
  city: Scalars['String'];
  phonePrefix: Scalars['String'];
  ico?: Maybe<Scalars['String']>;
  /** max number of characters: 7 */
  postalCode: Scalars['String'];
  email: Scalars['String'];
  /** max number of characters: 48 */
  streetInvoice?: Maybe<Scalars['String']>;
  /** max number of characters: 70 */
  note?: Maybe<Scalars['String']>;
};

export enum ParcelSize {
  L = 'L',
  M = 'M',
  S = 'S',
  XL = 'XL'
}

export enum PayUStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  REFUNDED = 'REFUNDED',
  WAITING_FOR_CONFIRMATION = 'WAITING_FOR_CONFIRMATION'
}

export type PayURequest = {
  orderId: Scalars['String'];
  paymentId: Scalars['String'];
  redirectUri: Scalars['String'];
  status: PayUStatus;
};

export type DepotValidationInput = {
  zip: Scalars['String'];
  country: Country;
};

export type CasesPayload = {
  cases: Array<Maybe<CaseData>>;
  count: Scalars['Int'];
};


export type ConfigData = {
  data?: Maybe<Scalars['String']>;
};

export enum AddressType {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON'
}

export type PlaceAddress = {
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  type?: Maybe<PlaceType>;
};

export type AddressQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type AddressQuery = { address?: Maybe<{ id: string, type: AddressType, name: string, name2?: Maybe<string>, ico?: Maybe<string>, dic?: Maybe<string>, street: string, city: string, zip: string, country: Country, email?: Maybe<string>, phonePrefix?: Maybe<string>, phone?: Maybe<string>, forInvoicing: boolean }> };

export type AddressesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type AddressesQuery = { addresses?: Maybe<Array<Maybe<{ id: string, type: AddressType, name: string, name2?: Maybe<string>, ico?: Maybe<string>, dic?: Maybe<string>, street: string, city: string, zip: string, country: Country, email?: Maybe<string>, phonePrefix?: Maybe<string>, phone?: Maybe<string>, forInvoicing: boolean }>>> };

export type BanksQueryVariables = Exact<{ [key: string]: never; }>;


export type BanksQuery = { banks?: Maybe<Array<Maybe<{ code: string, name: string }>>> };

export type CaseQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type CaseQuery = { case?: Maybe<{ doNotTip: boolean, fragile: boolean, id?: Maybe<string>, language: string, sendHow: PickupType, sendWhen?: Maybe<string>, status?: Maybe<CaseStatus>, tracking?: Maybe<string>, srcPudoId?: Maybe<string>, cashOnDelivery?: Maybe<{ cashOnDeliveryValue: number, variableSymbol?: Maybe<string>, account: { bankCode?: Maybe<string>, number?: Maybe<string>, iban?: Maybe<string>, swift?: Maybe<string> } }>, items: Array<{ size: ParcelSize, weight?: Maybe<number>, parcelNumber?: Maybe<any>, parcelValue?: Maybe<number> }>, price: { anotherCountry: number, basePrice: number, cashOnDelivery: number, courierDelivery: number, courierPickup: number, extraInsurance: number, total: number }, recipient: { city: string, companyName?: Maybe<string>, country: Country, dic?: Maybe<string>, email: string, ico?: Maybe<string>, name?: Maybe<string>, note?: Maybe<string>, phone: string, phonePrefix: string, postalCode: string, street: string, type: AddressType }, sender: { city: string, cityInvoice?: Maybe<string>, country: Country, countryInvoice?: Maybe<Country>, dic?: Maybe<string>, dicInvoice?: Maybe<string>, email: string, ico?: Maybe<string>, icoInvoice?: Maybe<string>, name: string, nameInvoice?: Maybe<string>, note?: Maybe<string>, phone: string, phonePrefix: string, postalCode: string, postalCodeInvoice?: Maybe<string>, street: string, streetInvoice?: Maybe<string>, type: AddressType, typeInvoice?: Maybe<AddressType> }, utm?: Maybe<{ campaign?: Maybe<string>, content?: Maybe<string>, medium?: Maybe<string>, source?: Maybe<string> }>, destAddress?: Maybe<{ id?: Maybe<string>, type?: Maybe<PlaceType>, name?: Maybe<string>, street?: Maybe<string>, city?: Maybe<string>, postalCode?: Maybe<string>, country?: Maybe<Country> }> }> };

export type CasesQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type CasesQuery = { cases?: Maybe<{ count: number, cases: Array<Maybe<{ id?: Maybe<string>, sendHow: PickupType, sendWhen?: Maybe<string>, status?: Maybe<CaseStatus>, doNotTip: boolean, fragile: boolean, language: string, srcPudoId?: Maybe<string>, recipient: { name?: Maybe<string>, street: string, city: string, postalCode: string, country: Country }, sender: { name: string, street: string, city: string, postalCode: string, country: Country }, price: { anotherCountry: number, basePrice: number, cashOnDelivery: number, courierPickup: number, courierDelivery: number, extraInsurance: number, total: number }, items: Array<{ size: ParcelSize, weight?: Maybe<number>, parcelNumber?: Maybe<any>, parcelValue?: Maybe<number> }>, destAddress?: Maybe<{ id?: Maybe<string>, type?: Maybe<PlaceType>, name?: Maybe<string>, street?: Maybe<string>, city?: Maybe<string>, postalCode?: Maybe<string>, country?: Maybe<Country> }> }>> }> };

export type ConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type ConfigQuery = { config?: Maybe<{ data?: Maybe<string> }> };

export type ConsentQueryVariables = Exact<{ [key: string]: never; }>;


export type ConsentQuery = { consent: boolean };

export type CreateCaseMutationVariables = Exact<{
  data: CaseDataInput;
}>;


export type CreateCaseMutation = { createCase?: Maybe<{ doNotTip: boolean, fragile: boolean, id?: Maybe<string>, language: string, sendHow: PickupType, sendWhen?: Maybe<string>, status?: Maybe<CaseStatus>, tracking?: Maybe<string>, srcPudoId?: Maybe<string>, cashOnDelivery?: Maybe<{ cashOnDeliveryValue: number, variableSymbol?: Maybe<string>, account: { bankCode?: Maybe<string>, number?: Maybe<string>, iban?: Maybe<string>, swift?: Maybe<string> } }>, items: Array<{ size: ParcelSize, weight?: Maybe<number>, parcelNumber?: Maybe<any>, parcelValue?: Maybe<number> }>, price: { anotherCountry: number, basePrice: number, cashOnDelivery: number, courierPickup: number, courierDelivery: number, extraInsurance: number, total: number }, recipient: { city: string, companyName?: Maybe<string>, country: Country, dic?: Maybe<string>, email: string, ico?: Maybe<string>, name?: Maybe<string>, note?: Maybe<string>, phone: string, phonePrefix: string, postalCode: string, street: string, type: AddressType }, sender: { city: string, cityInvoice?: Maybe<string>, country: Country, countryInvoice?: Maybe<Country>, dic?: Maybe<string>, dicInvoice?: Maybe<string>, email: string, ico?: Maybe<string>, icoInvoice?: Maybe<string>, name: string, nameInvoice?: Maybe<string>, note?: Maybe<string>, phone: string, phonePrefix: string, postalCode: string, postalCodeInvoice?: Maybe<string>, street: string, streetInvoice?: Maybe<string>, type: AddressType, typeInvoice?: Maybe<AddressType> }, utm?: Maybe<{ campaign?: Maybe<string>, content?: Maybe<string>, medium?: Maybe<string>, source?: Maybe<string> }>, destAddress?: Maybe<{ id?: Maybe<string>, type?: Maybe<PlaceType>, name?: Maybe<string>, street?: Maybe<string>, city?: Maybe<string>, postalCode?: Maybe<string>, country?: Maybe<Country> }> }> };

export type CreatePaymentMutationVariables = Exact<{
  redirectUrl: Scalars['String'];
  id: Scalars['UUID'];
}>;


export type CreatePaymentMutation = { createPayment?: Maybe<{ orderId: string, redirectUri: string }> };

export type DeleteAccountMutationVariables = Exact<{
  reason: Scalars['String'];
}>;


export type DeleteAccountMutation = { deleteAccount: boolean };

export type DeleteAddressMutationVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type DeleteAddressMutation = { deleteAddress: boolean };

export type PaymentQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type PaymentQuery = { payment?: Maybe<{ orderId: string, status: PayUStatus }> };

export type PickupTimeQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type PickupTimeQuery = { pickupTime?: Maybe<{ fromHour: number, toHour: number }> };

export type ProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileQuery = { profile?: Maybe<{ email?: Maybe<string>, emailVerified: boolean, phone?: Maybe<string>, phonePrefix?: Maybe<string>, name?: Maybe<string>, account?: Maybe<{ bankCode?: Maybe<string>, number?: Maybe<string> }> }> };

export type ProfileWithAdressessQueryVariables = Exact<{ [key: string]: never; }>;


export type ProfileWithAdressessQuery = { profile?: Maybe<{ email?: Maybe<string>, emailVerified: boolean, phone?: Maybe<string>, phonePrefix?: Maybe<string>, name?: Maybe<string>, account?: Maybe<{ bankCode?: Maybe<string>, number?: Maybe<string> }> }>, addresses?: Maybe<Array<Maybe<{ id: string, type: AddressType, name: string, name2?: Maybe<string>, ico?: Maybe<string>, dic?: Maybe<string>, street: string, city: string, zip: string, country: Country, email?: Maybe<string>, phonePrefix?: Maybe<string>, phone?: Maybe<string>, forInvoicing: boolean }>>> };

export type QrCodesQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type QrCodesQuery = { qrCodes?: Maybe<Array<Maybe<string>>> };

export type RouteQueryVariables = Exact<{
  country: Country;
  zip: Scalars['String'];
}>;


export type RouteQuery = { route?: Maybe<{ depot: string, mpsAllowed?: Maybe<string>, sort?: Maybe<string> }> };

export type SaveAddressMutationVariables = Exact<{
  address: SaveAddressInput;
}>;


export type SaveAddressMutation = { saveAddress?: Maybe<{ id: string, type: AddressType, name: string, name2?: Maybe<string>, ico?: Maybe<string>, dic?: Maybe<string>, street: string, city: string, zip: string, country: Country, email?: Maybe<string>, phonePrefix?: Maybe<string>, phone?: Maybe<string>, forInvoicing: boolean }> };

export type SaveConfigMutationVariables = Exact<{
  config: SaveConfigInput;
}>;


export type SaveConfigMutation = { saveConfig?: Maybe<{ data?: Maybe<string> }> };

export type SaveConsentMutationVariables = Exact<{
  consent: SaveConsentInput;
}>;


export type SaveConsentMutation = { saveConsent?: Maybe<{ dateTime?: Maybe<any>, email?: Maybe<string>, granted: boolean }> };

export type SaveProfileMutationVariables = Exact<{
  profile: SaveProfileInput;
}>;


export type SaveProfileMutation = { saveProfile?: Maybe<{ name?: Maybe<string> }> };

export type StoreAccountSourceMutationVariables = Exact<{
  source: Scalars['String'];
}>;


export type StoreAccountSourceMutation = { storeAccountSource: boolean };

export type VerifyEmailMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type VerifyEmailMutation = { verifyEmail?: Maybe<string> };
